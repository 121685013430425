import React, {useEffect, useState} from "react";
import {Box, Grid} from "@mui/material";
import {ErrorMessage} from "@hookform/error-message";
import {Controller, useForm} from "react-hook-form";
import OTPInput from "react-otp-input";
import {signInWithPhoneNumber, RecaptchaVerifier} from "firebase/auth";
import * as Sentry from "@sentry/browser";
import {auth} from "../Firebase";
import {numbersOnly} from "../helpers/numbers";
import {savePhone, saveUser} from "../app/storage";
import MaskedTextField from "./MaskedTextField";
import {Button} from "./Button";
import {PRIMARY} from "../app/colors";
import styles from "../components/auth.module.css";
import TextField from "@mui/material/TextField";

const masks = [{mask: "(00) 00000-0000"}, {mask: "+00 00000-0000"}];

type AuthProps = {
  captchaId: string;
  setRecaptchaEnabled: (enabled: boolean) => void;
  successCallback: (args: any, phone: string, email?: string) => Promise<void>;
  withEmail?: boolean;
};

export default function Auth(props: AuthProps) {
  const {
    control,
    handleSubmit,
    formState: {errors},
    clearErrors,
  } = useForm();

  const [showTokenInput, setShowTokenInput] = useState<boolean>(false);
  const [otp, setOtp] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [confirmationResult, setConfirmationResult] = useState<any>(undefined);
  const [verifier, setVerifier] = useState<any>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);

  // const router = useRouter();

  useEffect(() => {
    const recaptcha = new RecaptchaVerifier(auth, props.captchaId, {
      callback: (response: any) => {
        props.setRecaptchaEnabled(false);
        setLoading(true);
        console.log("prepared phone auth process", response?.[0]);
      },
      "expired-callback": () => {
        console.error("ERRO EXPIRED RECAPTCHA: ");
        setLoading(false);
        setDisabled(false);
      },
      "error-callback": (error: any) => {
        console.error("ERRO RECAPTCHA: ", error);
        setLoading(false);
        setDisabled(false);
      },
    });

    setVerifier(recaptcha);
  }, []);

  const back = () => {
    // router.replace("/");
  };

  const injectCharacter = (text: string, char: string, position: number) => {
    return text.slice(0, position) + char + text.slice(position);
  };

  const normalizePhone = (rawPhone: string) => {
    const international = rawPhone.startsWith("+");
    const phone = numbersOnly(rawPhone);

    if (international) {
      return `+${phone}`;
    } else {
      console.log("phone.length ", phone.length);
      if (phone.length === 10) {
        return `+55${injectCharacter(phone, "9", 3)}`;
      }
      return `+55${phone}`;
    }
  };

  const onSubmitPhone = async (payload: any) => {
    setDisabled(true);

    const rawPhone = payload.phone;

    const phone = normalizePhone(rawPhone);

    console.log("normalizedPhone", phone);

    try {
      Sentry.setUser({username: phone});
    } catch (e) {
      console.log("Sentry.setUser error", e);
    }

    setPhoneNumber(phone);
    setEmail(payload.email);

    signInWithPhoneNumber(auth, phone, verifier)
      .then((_confirmationResult: any) => {
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        setConfirmationResult(_confirmationResult);

        clearErrors();
        setLoading(false);
        setShowTokenInput(true);
        // ...
      })
      .catch((error: any) => {
        console.log("signInWithPhoneNumber error", error);
        setLoading(false);
        setDisabled(false);
      });
  };

  const onSubmitCodes = async (payload: any) => {
    clearErrors();
    setLoading(true);

    confirmationResult
      ?.confirm(otp)
      .then((result: any) => {
        console.log("RESULT USER", result);
        if (result.user) {
          saveUser(result.user);
          savePhone(phoneNumber);
          try {
            Sentry.setUser({username: phoneNumber});
          } catch (e) {
            console.log("Sentry.setUser error", e);
          }
          props.successCallback(result.user, phoneNumber, email);
        }
        setLoading(false);
        setDisabled(false);
      })
      .catch((error: any) => {
        console.log("err", error);
        setLoading(false);
        setDisabled(false);
      });
  };

  return (
    <>
      {!showTokenInput ? (
        <form onSubmit={handleSubmit(onSubmitPhone)} noValidate>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <Box pl={1} pb={3}>
                {/*<Logo fontSize={"2em"}/>*/}
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="phone"
                control={control}
                defaultValue=""
                rules={{
                  required: true,
                }}
                render={({field: {onChange, value}}: any) => (
                  <MaskedTextField
                    InputProps={{
                      className: styles.input,
                    }}
                    InputLabelProps={{
                      style: {
                        color: "#fff",
                        backgroundColor: PRIMARY,
                        padding: "5px",
                        borderRadius: "5px",
                      },
                    }}
                    mask={masks}
                    onChange={onChange}
                    value={value}
                    required
                    label="Celular"
                    sx={{input: {color: "white", textIndent: "15px"}}}
                    variant="outlined"
                    error={!!errors.phone}
                    helperText={errors.phone && "Celular é obrigatório"}
                    fullWidth
                  />
                )}
              />
              <ErrorMessage errors={errors} name="singleErrorInput"/>
            </Grid>
            {/* TODO: Uncoment this when we have email login
            <Grid item xs={12} mt={2}>
              <Controller
                name="email"
                control={control}
                defaultValue=""
                rules={{
                  required: true,
                }}
                render={({field: {onChange, value}}: any) => (
                  <TextField
                    type={"email"}
                    InputProps={{
                      className: styles.input,
                    }}
                    InputLabelProps={{
                      style: {
                        color: "#fff",
                        backgroundColor: PRIMARY,
                        padding: "5px",
                        borderRadius: "5px",
                      },
                    }}
                    onChange={onChange}
                    value={value}
                    required
                    label="Email"
                    sx={{input: {color: "white", textIndent: "15px"}}}
                    variant="outlined"
                    error={!!errors.email}
                    helperText={errors.email && "Email é obrigatório"}
                    fullWidth
                  />
                )}
              />
              <ErrorMessage errors={errors} name="singleErrorInput"/>
            </Grid>
            */}
          </Grid>
          <Grid item xs={12} mt={3}>
            <Button loading={loading} disabled={disabled}>
              Enviar código
            </Button>
          </Grid>
        </form>
      ) : (
        <Box mt={2}>
          <OTPInput
            value={otp}
            inputType={"tel"}
            skipDefaultStyles={true}
            containerStyle={{
              justifyContent: "center",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginTop: "1rem",
              padding: "1rem",
            }}
            inputStyle={{
              width: "2.75rem",
              height: "3rem",
              textAlign: "center",
              fontSize: "2rem",
              margin: "0 0.5rem",
              background: "#d0dfeb",
              border: "none",
              color: "#333",
            }}
            onChange={setOtp}
            numInputs={6}
            renderInput={(props: any) => <input {...props} />}
          />
          <Box mt={5}>
            <small
              style={{
                color: "white",
                textAlign: "center",
                display: "inline-block",
                width: "100%",
                fontSize: "1em",
              }}
            >
              Código enviado para: {phoneNumber}
            </small>
          </Box>
          <Box mt={5}>
            <form onSubmit={handleSubmit(onSubmitCodes)} noValidate>
              <Button loading={loading}>Entrar</Button>
            </form>
            <Button
              cancel
              onClick={() => {
                setShowTokenInput(false);
                setDisabled(false);
              }}
            >
              Voltar
            </Button>
          </Box>
        </Box>
      )}
    </>
  );
}

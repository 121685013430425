import React, {useEffect, useState} from "react";
import "./ProjectDetails.scss";
import Skeleton from "react-loading-skeleton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faExternalLink,
  faInfoCircle
} from "@fortawesome/free-solid-svg-icons";
import {faInstagram} from "@fortawesome/free-brands-svg-icons";

import FullModal from "../../../../components/FullModal/FullModal";
import {getProjectById, Project} from "../../../../api/projects";
import {TransparencyContent} from "../../../orgs/[id]/transparencia/content";
import DonateForm from "../../../orgs/components/DonateForm/DonateForm";

export default function ProjectDetails(props: ProjectDetailsItemProps) {
  const projectId = props.project?.id;

  const [project, setProject] = useState<Project>();
  const [isDonating, setIsDonating] = useState<boolean>(false);
  const [showTransparency, setShowTransparency] = useState<boolean>(false);

  useEffect(() => {
    if (projectId) {
      getProjectById(projectId).then((project) => {
        setProject(project);
      });
    }
  }, [projectId]);

  return (
    <div className="project-details">
      <div className="project-details-header">
        <div className="project-details-header-main">
          {project ? (
            <img
              src={project.organization.logo}
              className="project-details-logo"
              width={120}
              height={120}
            />
          ) : (
            <Skeleton width={120} height={120}/>
          )}
          <div className="project-details-header-info">
            <span className="project-details-name">
              {project ? project.organization.name : <Skeleton width={100}/>}
            </span>
            <span className="org-category-badge">
              {project ? (
                project.incentiveLaw?.name
              ) : (
                <Skeleton width={100}/>
              )}
            </span>
            {project && project.organization ? (
              <div>
            <span className="project-details-ong-headline">
              {project.organization.headline}
            </span>
              </div>
            ) : (
              <Skeleton height={50}/>
            )}
          </div>
        </div>
        <div className="project-details-header-contact">
          {project && project.organization.social ? (
            <a href={project.organization.social} target="_blank" className={"link"}>
              <FontAwesomeIcon icon={faInstagram}/>
              <span>Instagram</span>
            </a>
          ) : (
            <></>
          )}

          {project && project.organization.link ? (
            <a href={project.organization.link} target="_blank" className={"link"}>
              <FontAwesomeIcon icon={faExternalLink}/>
              <span>Site</span>
            </a>
          ) : (
            <></>
          )}

          {project ? (
            <span onClick={(e) => setShowTransparency(true)} className={"link span-link"}>
              <FontAwesomeIcon icon={faInfoCircle}/>
              <span>Saiba mais</span>
            </span>
          ) : (
            <Skeleton/>
          )}
        </div>
      </div>
      <div>
        {project && project.organization ? (
          <div>
            <span className="project-details-ong-description">
              {project.organization.description}
            </span>
          </div>
        ) : (
          <Skeleton height={50}/>
        )}

      </div>
      <hr style={{marginTop: "25px", opacity: 0.5}}/>
      {project && project.organization ? (
        <div className={"project-details-container"}>
          <div>
            <span className="project-details-title">
              PROJETO
            </span>
          </div>
          <div>
            <span className="project-details-name">
              {project.name}
            </span>
          </div>
        </div>
      ) : (
        <Skeleton height={70}/>
      )}

      <div className="project-details-image-list">
        {project ? (
          project.organization.images?.map((img) => <img key={img.url} src={img.url}/>)
        ) : (
          <Skeleton
            containerClassName="skeleton-images-list"
            count={3}
            width="33%"
            height={70}
          />
        )}
      </div>

      {project ? (
        <span className="project-details-description">
          {project.description}
        </span>
      ) : (
        <Skeleton height={70}/>
      )}

      <div className="project-details-invest">
        <button disabled={!project} onClick={() => setIsDonating(true)}>
          Quero doar
        </button>
      </div>

      {isDonating && project && (
        <FullModal
          order={{
            id: "donate-form",
            sequence: 2,
          }}
          closeModal={() => setIsDonating(false)}
        >
          <DonateForm
            project={project}
            organization={project.organization}
            onClose={() => setIsDonating(false)}
            onDoneDonating={() => setIsDonating(false)}
          />
        </FullModal>
      )}

      {project && showTransparency && (
        <FullModal closeModal={() => setShowTransparency(false)}>
          <TransparencyContent organizationId={project.organization.id}/>
        </FullModal>
      )}
    </div>
  );
}

interface ProjectDetailsItemProps {
  project?: Project;
}

import {useState} from "react";
import "./PortfolioItem2.scss";
import {Portfolio} from "../../../../api/portfolio";
import {Organization} from "../../../../api/organization";
import FullModal from "../../../../components/FullModal/FullModal";
import OrgDetails from "../../../orgs/components/OrgDetails/OrgDetails";
import {toReal} from "../../../../helpers/formatters";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faQuestionCircle} from "@fortawesome/free-solid-svg-icons";
import {Tooltip} from "react-tooltip";
import ProjectDetails from "../../../projects/components/ProjectDetails";

type PortfolioItemProps = {
  key: string
  portfolio: Portfolio;
};

const getPerformance = (organization: Organization) => {
  return organization.performances?.sort((a, b) => b.created_at.localeCompare(a.created_at))
    .slice(0, 3);
};

const getChartColor = (chartData: number[]): InterfaceColors => {
  const adjustment = 0.0;

  return chartData.length > 1
    ? chartData[0] > adjustment
      ? "#4caf50"
      : "#f44336"
    : "#324d8d";
};

const buildColors = (
  organization: Organization
): [number[], InterfaceColors] => {
  const performance = getPerformance(organization);

  const chartData = performance?.map((p) => (p?.value || 0) * 100.0) || [];

  const chartColor = getChartColor(chartData);

  return [chartData, chartColor];
};

export default function PortfolioItem2(props: PortfolioItemProps) {
  const {portfolio} = props;
  const {organization, project} = portfolio;
  console.log("inside PortfolioItem2");
  console.log("organization", organization);
  console.log();

  const [selectedOrg, setSelectedOrg] = useState<Organization | null>(null);

  if (!organization) return <></>;

  const [chartData, chartColor] = buildColors(organization);

  return (
    <div className="portfolio-item" key={props.key}>
      <div
        className="portfolio-item-org-description"
        onClick={() => setSelectedOrg(organization)}
      >
        <img src={organization.logo}/>
        <div>
          {
            portfolio.project ? (
              <h4>{portfolio.project.name}</h4>
            ) : <></>
          }
          <h3>{organization.name}</h3>
        </div>
      </div>

      <div>
        <h3 className="portfolio-item-org-amount">
          R$ {toReal(portfolio.amount)}
        </h3>
        {chartData?.[0] && false && (
          <h4
            style={{color: chartColor}}
            className="portfolio-item-org-percentage"
            data-tooltip-id="invest-tooltip"
            data-tooltip-html="<div>Percentual de retorno social</br>gerado pelo seu investimento</div>"
          >
            <span>+{chartData?.[0]?.toFixed(2)}%</span>

            <span className="portfolio-item-org-percentage-info">
              <FontAwesomeIcon
                icon={faQuestionCircle}
                color="orange"
                size="lg"
              />
            </span>
          </h4>
        )}

        <Tooltip id="invest-tooltip"/>
      </div>

      {selectedOrg && (
        <FullModal closeModal={() => setSelectedOrg(null)}>
          {project ? (
            <ProjectDetails project={project}/>
          ) : (
            <OrgDetails organization={selectedOrg}/>
          )}
        </FullModal>
      )}
    </div>
  );
}

type InterfaceColors = "#4caf50" | "#f44336" | "#324d8d";
